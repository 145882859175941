<template>
	<FloatActions :responsive="{ tablet: 1.9, mobile: 1.5 }" />
	<div v-if="!$isLoggedIn" ref="ref_whiteWall" id="white-wall" :class="{ show: pageHomeStore.wallVisible }">
		<MastHead />
	</div>
	<HomeSubnav ref="ref_homeSubNav" />

	<div id="content">
		<!-- https://next.router.vuejs.org/api/#route -->
		<router-view v-slot="{ Component }">
			<keep-alive>
				<component :is="Component" />
			</keep-alive>
		</router-view>
	</div>
</template>

<script>
// Stores
import { usePageHomeStore } from '@/stores/PageHomeStore'

// Templates
import HomeSubnav from '@/views/Home/HomeSubnav'

// Components
import FloatActions from '@/components/FloatActions'
import MastHead from '@/components/MastHead'

export default {
	name: 'HomeMain',
	components: {
		FloatActions,
		MastHead,
		HomeSubnav,
	},
	setup() {
		const pageHomeStore = usePageHomeStore()
		return { pageHomeStore }
	},
	watch: {
		'pageHomeStore.triggerActivate'(newValue) {
			if (newValue) {
				console.log('trigger')
				this.pageHomeStore.activate(this.$refs.ref_whiteWall)
			}
		},
	},
	mounted() {
		if (this.$isLoggedIn) return
		this.pageHomeStore.activate(this.$refs.ref_whiteWall)
	},
}
</script>

<style scoped lang="scss">
// White wall header
#white-wall {
	// background: pink;
	// border-bottom: solid 1px red;
	height: calc(100vh * 0.75);
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: none;
}
#white-wall.show {
	display: flex;
}

// Content
#content {
	margin-top: 1.5rem;
}

#white-wall.show ~ #content {
	margin-top: 0.5rem;
}

/**
 * Responsive
 */

@media only screen and (max-width: $tablet) {
	#white-wall {
		margin-top: -1.9rem;
	}
	#content {
		margin-top: 0.5rem;
	}
}

@media only screen and (max-width: $mobile) {
	#content {
		margin-top: 0.5rem;
	}
}
</style>
