<template>
	<div
		id="home-nav-wrap"
		ref="ref_homeNavWrap"
		:class="{ sticky: pageHomeStore.sticky, 'no-wall': $isLoggedIn }"
	>
		<div id="home-nav">
			<NavSub :routed="true" :items="navItems" :center="true" :exact="true" />
		</div>
	</div>
</template>

<script>
// Stores
import { usePageHomeStore } from '@/stores/PageHomeStore'

// Compomnents
import NavSub from '@/components/NavSub'

export default {
	name: 'HomeSubnav',
	components: {
		NavSub,
	},
	setup() {
		const pageHomeStore = usePageHomeStore()
		return { pageHomeStore }
	},
	data() {
		return {
			navItems: [
				{
					display: 'Featured',
					to: {
						name: 'Featured',
					},
				},
				{
					display: 'Explore',
					to: {
						name: 'Explore',
					},
				},
				{
					display: 'About',
					to: {
						name: 'About',
					},
				},
			],
		}
	},
}
</script>

<style scoped lang="scss">
// Sticky wrap
#home-nav-wrap {
	// background: yellow;
	width: 100%;
	margin-top: -0.5rem;
	margin-left: 0;
	text-align: center;
	font-size: 0;
	line-height: 0;
}
#home-nav-wrap.sticky {
	position: fixed;
	top: 1rem;
	z-index: 2; // See App.vue for z-index coordination.
	pointer-events: none;
}

// Nav
#home-nav {
	background: $bg;
	// background: lightblue !important;
	padding: 0.1rem 0.2rem 0 0.2rem;
	border-radius: $br;
	display: inline-block;
	pointer-events: auto;
}
#home-nav .subnav-wrap:deep() .subnav {
	margin-bottom: 0;

	// Override nav defaults.
	padding-left: 0;
	padding-right: 0;
}
#home-nav .subnav-wrap {
	height: 0.5rem;

	// Override nav defaults.
	margin-left: 0;
	margin-right: 0;
}

/**
 * Responsive
 */

@media only screen and (max-width: 1000px) {
	#home-nav-wrap.sticky {
		text-align: left;
		padding-left: calc(var(--pp) + 0.4rem);
	}
}

@media only screen and (max-width: $tablet) {
	#home-nav-wrap.sticky {
		padding-left: var(--pp);
		position: static;
	}
	#home-nav {
		padding-left: 0;
	}
	#home-nav-wrap #home-nav {
		background: transparent;
	}
}
</style>
